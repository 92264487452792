<template>
  <div class="pro-item content-hover" :key="_data.id" @click="goRouter('/training/trainDetail/',_data.id)" :class="_data.isArea === '0'?'background':''">
    <div class="top">
      <div class="info">
        <h5 class="line_clamp1">
          {{ _data.courseName }}
        </h5>
        <div class="types line_clamp1">
          <div class="name">适合类型</div>
          <el-popover class="box-item" trigger="hover"  placement="bottom" :disabled="_data.disabledLabelArr.length > 5 ? false : true">
            <div style="padding-top:4px">
              <div class="tooltip_contents">
                <div>
                  <disType :disTypes="_data.disTypeLevels" />
                </div>
              </div>
            </div>
            <div slot="reference" class="line_clamp1">
              <div>
                <span>{{_data.disTypeLevels}}</span>
              </div>
            </div>
          </el-popover>
        </div>
        <div class="city line_clamp1">
          <label class="name">户籍要求</label>
          <div v-if="_data.isArea === '0'">
            不限户籍
          </div>
          <div v-else>
            <el-tooltip class="box-item" effect="dark" placement="top-start" :disabled="_data.disabledLabelArr.length > 5 ? false : true">
              <div slot="content">
                <div class="tooltip_contents">
                  <template v-for="(item, index) in _data.courseAreas">
                    <span :key="index" style="padding-right: 5px">{{
                        (item.provinceid || '') + (item.cityid ? '-' + item.cityid : '') + (item.threeCityid ? '-' + item.threeCityid : '')
                      }}</span>
                  </template>
                </div>
              </div>
              <div class="line_clamp1">
                <div v-for="(item, index) in _data.courseAreas" :key="index">
                  <span :class="item.isTxt ? 'isTxt' : 'noTxt'">{{
                      (item.provinceid || '') + (item.cityid ? '-' + item.cityid : '') + (item.threeCityid ? '-' + item.threeCityid : '')
                    }}</span>
                </div>
              </div>
            </el-tooltip>
          </div>

        </div>
      </div>
      <div class="time">
        <template v-if="_data.status == 2">
          <span class="org">{{ $dateFormat(_data.appplyBeginTime, 'MM月DD日') }}开始报名</span>
        </template>
        <template v-if="_data.status == 1">
          <span class="green">{{ $dateFormat(_data.appplyEndTime, 'MM月DD日') }}截止报名</span>
        </template>
        <template v-if="_data.status == 3"><span class="yellow">待培训</span></template>
        <template v-if="_data.status == 4"><span class="blue">培训中</span></template>
        <template v-if="_data.status == 5"><span class="gray">培训已结束</span></template>
      </div>
    </div>
    <div class="address">

      <img src="@/static/project/position.png" alt="">
      <span>
        培训地点：{{
          ((_data.trainP || '') + (_data.trainC ? _data.trainP == _data.trainC ? '' : _data.cityid || '' : '') + (_data.trainT || ''))
        }}
      </span>
    </div>
  </div>
</template>
<script>
import { countDown, dateFormat } from '@/plugins/utils/utils.js';
import disType from '@/components/disType/index.vue';

export default {
  components: {
    disType
  },
  props: {
    data: {
      type: Object,
      default() {
        return {
          courseName: '默认数据',
          activeState: {
            state: 1,
            time: '8天'
          }
        };
      }
    }
  },
  data() {
    return {
      _data: {
        disabledLabelArr: []
      },
      timer: null
    };
  },
  watch: {
    data: {
      handler(val) {
        const data = this.data;
        const disabledLabel = data.disabledLabelAndLevelOutDTO || [];
        let disabledLabelArr = [];
        if (disabledLabel.length) {
          disabledLabelArr = disabledLabel.reduce((cur, item, index, arr) => {
            cur.push({
              isTxt: true,
              name: item.disabledType,
              level: item.disabledLevel
            });
            if (index < arr.length - 1) {
              cur.push({ isTxt: false, name: '|' });
            }
            return cur;
          }, []);
        }
        this._data = {
          ...data,
          disabledLabelArr,
          activeState: {
            ...countDown(data.appplyBeginTime, data.appplyEndTime, 'day')
          }
        };
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    goRouter(url, id) {
      this.$router.push({
        path: url,
        query: {
          id: id
        }
      });
    }
  },
  mounted() {
    if (this.timer) clearInterval(this.timer);
    this.timer = setInterval(() => {
      this._data = {
        ...this._data,
        activeState: {
          ...countDown(this._data.appplyBeginTime, this._data.appplyEndTime, 'day')
        }
      };
    }, 1 * 60 * 1000);
  },
  destroyed() {
    if (this.timer) clearInterval(this.timer);
  }
};
</script>
<style lang='scss' scoped>
.pro-item {
  position: relative;
  display: inline-block;
  width: calc(50% - 6px);
  height: 202px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(0, 18, 10, 0.05);
  padding: 18px;
  box-sizing: border-box;
  margin-bottom: 24px;
  margin-right: 12px;
  vertical-align: top;
  cursor: pointer;

  &.background {
    //
    background-image: url('~static/project/huji.png');
    background-position: 103% 62px;
    background-repeat: no-repeat;
  }

  &:nth-of-type(2n) {
    margin-right: 0;
  }

  &:nth-last-child(1),
  &:nth-last-child(2) {
    margin-bottom: 12px !important;
  }

  // &:nth-last-of-type(1) {
  //   margin-bottom: 12px !important;
  // }
  // &:nth-last-of-type(2) {
  //   margin-bottom: 12px !important;
  // }

  .top {
    display: flex;
    justify-content: space-between;

    .info {
      flex: 1;
      overflow: hidden;
    }
  }

  h5 {
    font-size: 20px;
    font-weight: bold;
    color: #00120a;
    line-height: 28px;

    a {
      color: #00120a;
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
    }
  }

  .types {
    display: flex;
    align-items: center;
    margin-top: 24px;

    div {
      color: #66716c;
      font-size: 16px;
      line-height: 22px;
      cursor: default;

      &.name {
        font-size: 16px;
        color: #00120a;
        line-height: 22px;
        margin-right: 6px;
        cursor: pointer;
        flex-shrink: 0;
      }

      span {
        font-size: inherit;
        cursor: pointer;
      }

      span.noTxt {
        margin: 0 5px;
      }

      &:nth-child(2) {
        flex: 1;
      }
    }
  }

  .time {
    transform: translateX(18px);

    span {
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 34px;
      border-radius: 2px 0px 0px 2px;
      border: 1px solid;
      border-image: linear-gradient(90deg, rgba(255, 150, 0, 0.06), rgba(255, 150, 0, 0)) 1 1;
      font-size: 14px;
      color: #ff9600;
      line-height: 22px;
      padding: 6px 12px;

      &.org {
        color: rgba(255, 150, 0, 1);
        background: rgba(255, 150, 0, 0.12);
        border-image: linear-gradient(90deg, rgba(255, 150, 0, 0.06), rgba(255, 150, 0, 0)) 1 1;
      }

      &.green {
        color: #56c1ab;
        background: rgba(86, 193, 171, 0.12);
        border-image: linear-gradient(90deg, rgba(86, 193, 171, 0.06), rgba(86, 193, 171, 0)) 1 1;
      }

      &.blue {
        color: #0077bb;
        // background: #0077bb;
        background: rgba(0, 119, 187, 0.12);
        border-image: linear-gradient(90deg, rgba(0, 119, 187, 0.06), rgba(0, 119, 187, 0)) 1 1;
      }

      &.gray {
        color: #66716c;
        background: rgba(102, 113, 108, 0.12);
        border-image: linear-gradient(90deg, rgba(102, 113, 108, 0.06), rgba(102, 113, 108, 0)) 1 1;
      }

      &.yellow {
        color: #e6a23c;
        background: rgba(230, 162, 60, 0.12);
        border-image: linear-gradient(90deg, rgba(230, 162, 60, 0.06), rgba(230, 162, 60, 0)) 1 1;
      }
    }
  }

  .city {
    margin-top: 12px;
    color: #66716c;
    font-size: 16px;
    display: flex;
    align-items: center;

    label {
      display: inline-block;
      width: 64px;
      font-size: 16px;
      color: #00120a;
      line-height: 22px;
      text-align-last: justify;
      text-align: justify;
      text-justify: distribute;
      vertical-align: middle;
      margin-right: 6px;
      cursor: pointer;
    }

    div,
    span {
      font-size: 16px;
      padding-right: 5px;
    }
  }

  .address {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 41px;
    background: linear-gradient(
      180deg,
      rgba(143, 226, 213, 0.12) 0%,
      rgba(86, 193, 171, 0.08) 100%
    );
    border-radius: 0px 0px 2px 2px;
    padding-left: 18px;

    img {
      width: 18px;
      margin-right: 6px;
    }

    font-size: 16px;
    color: #66716c;
    line-height: 24px;
  }

  .btns {
    display: flex;
    height: 58px;
    justify-content: space-between;
    align-items: center;

    li:nth-of-type(1) {
      color: #99a09d;
      font-size: 14px;

      span {
        color: #ff9600;
        font-size: inherit;
        font-weight: 600;

        &.gray {
          color: #99a09d;
        }
      }
    }

    li:nth-of-type(2) {
      button {
        width: 94px;
        line-height: 34px;
        background: #56c1ab;
        border-radius: 31px;
        text-align: center;
        cursor: pointer;
        border: none;
        color: #ffffff;
        font-size: 14px;
        padding: 0;

        :deep(span) {
          line-height: 0;
        }

        &.disable {
          background: #99a09d;
        }
      }
    }
  }
}

.tooltip_contents {
  max-width: 480px;
}
</style>
