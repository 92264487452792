
<template>
  <div class="train_wrap" v-loading="loading" element-loading-text="拼命加载中">
    <div class="train">
      <el-tabs
        v-model="activeName"
        type="border-card"
        @tab-click="clickTable"
        class="tabs"
      >
        <el-tab-pane label="线下培训" name="first" class="pane">
          <trainList/>
          <!-- <div class="category">
            <span class="title">培训类别：</span>
            <el-radio-group v-model="form.trainType" class="world">
              <ul class="top flex-wrap">
                <li class="">
                  <el-radio label="">全部</el-radio>
                </li>
                <li v-for="(n, i) in trainTab" :key="i" v-show="i < 9">
                  <el-radio :label="n.value">{{ n.label }}</el-radio>
                </li>
              </ul>
            </el-radio-group>
          </div>
          <div class="content">
            <ul>
              <li v-for="(item, index) in trainList" :key="index" @click="goRouter('/training/trainDetail/',item.id)">
                <div class="left">
                  <img :src="item.picurl" alt=""  class="err_image"/>
                </div>
                <div class="text">
                  <h3>{{ item.trainName }}</h3>
                  <p>
                    <img src="@/static/lanzhou/icon/didian.png" alt="" />
                    <span
                      >{{ item.trainBeginTime }}——{{ item.trainEndTime }}</span
                    >
                  </p>
                  <p>
                    <img src="@/static/lanzhou/icon/rili.png" alt="" />
                    <span>{{ item.trainAddress }}</span>
                  </p>
                </div>
              </li>
              <status :type="status.type" v-if="status.isHide"></status>
            </ul>
          </div>
          <div class="pages">
            <el-pagination
              background
              class="work_pagination"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="form.pageNum"
              :page-size="form.pageSize"
              prev-text="上一页"
              next-text="下一页"
              layout="prev, pager, next, total, jumper"
              :total="form.total"
            >
            </el-pagination>
          </div> -->
        </el-tab-pane>
        <el-tab-pane label="在线学习" name="second" class="pane">
          <div class="category">
            <span class="title">培训类别：</span>
            <el-radio-group v-model="page.projectType" class="world">
              <ul>
                <li>
                  <el-radio label="">全部</el-radio>
                </li>
                <li v-for="(n, i) in publicTab" :key="i" v-show="i < 9">
                  <el-radio :label="n.value">{{ n.label }}</el-radio>
                </li>
              </ul>
            </el-radio-group>
          </div>
          <div class="content">
            <ul>
              <li v-for="(item, index) in publicList" :key="index" @click="goRouter('/training/publicDetail/',item.id)">
                <div class="left">
                  <img :src="item.imgurl" alt="" />
                </div>
                <div class="text">
                  <h3>{{ item.trainName }}</h3>
                  <p>
                    <span>课件类型：视频</span>
                  </p>
                  <p>
                    <span>课件章节：{{ item.reamark }}</span>
                  </p>
                </div>
              </li>
              <status :type="status.type" v-if="status.isHide"></status>
            </ul>
          </div>
          <div class="pages">
            <el-pagination
              v-if="page.total"
              background
              class="work_pagination"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="page.pageNum"
              :page-size="page.pageSize"
              prev-text="上一页"
              next-text="下一页"
              layout="prev, pager, next, total, jumper"
              :total="page.total"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import status from "@/components/public/status";
import trainList from '@/components/public/trainList';

export default {
  components: {
    status,
    trainList
  },
  metaInfo: {
    title: "职业培训-兰州市残疾人就业创业网络服务平台",
    meta: [
      {
        name: "keywords",
        content:
            "残疾人职业培训,残疾人公开课,残疾人技能提升,残疾人技能学习",
      },
      {
        name: "description",
        content:
            "兰州市残疾人就业创业网络服务平台,职业培训版块为残疾人提供免费的残疾人技能提升,和公开课资源,旨在为残疾人“赋能”,残疾人可以通过职业培训版块学习到职业规划、简历优化、个人成长、时间管理等技能培训。",
      },
    ],
  },
  data() {
    return {
      activeName: "first",
      publicTab: [],
      trainTab: [],
      page: {
        total: 0,
        pageNum: 1,
        pageSize: 6,
        projectType: "",
        videoType:"03"
      },
      form: {
        trainType: "",
        disType: "",
        appplyBeginTime: "",
        trainEndTime: "",
        trainC: "",
        trainT: "",
        total: 0,
        pageNum: 1,
        pageSize: 6,
        trainP: "",
      },
      trainList: [],
      publicList: [],
      loading: false,
      status: {
        isHide: false,
        type: "",
      },
    };
  },
  watch: {
    form: {
      handler(newName, oldName) {
        this.train();
      },
      deep: true,
    },
    page: {
      handler(newName, oldName) {
        this.publicApi();
      },
      deep: true,
    },
  },
  methods: {
    // 切换选项卡
    clickTable() {
      if (this.activeName == "first") {
      } else {
        if(this.publicList.length<=0){
          this.publicSelectList();
          this.publicApi();
        }
      }
    },
    //公开课筛选项
    publicSelectList() {
      this.$api.selectListApi({ dictCode: "open_train_class" }).then((res) => {
        if (res.data.success) {
          this.publicTab = res.data.data;
        }
      });
    },
    //培训筛选项
    selectList() {
      this.$api.selectListApi({ dictCode: "train_type" }).then((res) => {
        if (res.data.success) {
          this.trainTab = res.data.data;
        }
      });
    },
    // 培训列表（线下培训）
    async train() {
      let res = await this.$api.trainListApi(this.form);
      try {
        this.trainList = res.data.data.records;
        this.form.total = res.data.data.total;
        if (this.trainList.length <= 0) {
          this.status.isHide = true;
          this.status.type = "result";
        } else {
          this.status.isHide = false;
        }
      } catch (e) {
        this.loading = false;
        this.status.isHide = true;
        this.status.type = "wifi";
      }
    },
    // 公开课列表（线上培训）
    async publicApi(val) {
      try {
        let res = await this.$api.publicListApi(this.page);
        this.loading = false;
        this.publicList = res.data.data.records;
        this.page.total = res.data.data.total;
        if (this.publicList.length <= 0) {
          this.status.isHide = true;
          this.status.type = "result";
        } else {
          this.status.isHide = false;
        }
      } catch (e) {
        this.loading = false;
        this.status.isHide = true;
        this.status.type = "wifi";
      }
    },
    //公开课分页
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.publicApi();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    goRouter(url,id){
      const {href} = this.$router.resolve({
        path:url,
        query:{
          id:id
        }
      })
      window.open(href,'_blank')
    },
  },
  created() {
    this.selectList();
    this.train();
    //判断是否移动端
    this.$mobile("https://lzmz.cdpee.org.cn/#/pages/train/index");
  },

};
</script>
<style lang="less">
.tabs {
  .el-tabs--border-card {
    border: 0 none;
    box-shadow: none;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item:first-child{
      margin-left: 1px;
  }
  .el-tabs__nav {
    width: 100%;
  }
  .el-tabs__content {
    padding: 0;
    background-color: white;
  }
  .el-tabs__header {
    background-color: #fff;
    border: #ededed solid 1px;
    .el-tabs__item.is-active {
      border: none;
      color: #fff;
      background-color: #00924b;
    }
  }
  .el-tabs__item {
    float: left;
    width: 50%;
    text-align: center;
    height: 49px;
    padding: 0px !important;
    font-size: 16px;
    line-height: 49px;
  }
}
.train_wrap {
  background: #fff;
  .train {
    width: 1200px;
    margin: 0 auto;
    padding-top: 10px;
    .tabs {
      .pane {
        .category {
          padding: 15px 30px 30px;
          border: 1px solid #ededed;
          .title {
            margin-left: 50px;
            line-height: 40px;
          }
          .world {
            ul {
              display: flex;
              width: 900px;
              justify-content: space-around;
              li {
                cursor: pointer;
              }
            }
          }
        }
        .content {
          &>ul {
            display: flex;
            flex-wrap: wrap;
            li {
              padding: 10px 15px;
              display: flex;
              width: 595px;
              border: 1px solid #ededed;
              margin-bottom: 10px;
                cursor: pointer;
              &:nth-of-type(2n) {
                margin-left: 8px;
              }
              .left {
                margin-right: 20px;
                img {
                  width: 160px;
                  height: 160px;
                }
              }
              .text {
                h3 {
                  padding: 10px 0 20px 0;
                }
                p {
                  padding-bottom: 5px;
                  img {
                    transform: translateY(2px);
                    margin-right: 10px;
                  }
                  span {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>